@use '@src/_variables.scss' as v;

.toggle-switch {
  display: flex;
  align-items: center;
  gap: 20px;

  *,
  :after,
  :before {
    color: v.$navy;
    font-family: v.$font-family-roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0 solid #edf0f7;
  }

  .toggle-button {
    position: relative;
    display: inline-flex;
    height: 1.5rem;
    width: 2.75rem;
    align-items: center;
    border-radius: 9999px;
    opacity: 1;
    background-color: v.$light-grey;

    &.-active {
      background-color: v.$primary-color;
    }
  }

  .toggle-label {
    display: flex;
    width: 176px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: start;
  }

  .toggle-circle {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    transform: translate(var(--tw-translate-x), 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
    border-radius: 9999px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    opacity: 1;
    background-color: v.$white;
    --tw-translate-x: 0.25rem;

    &.-active {
      --tw-translate-x: 1.5rem;
    }
  }
}
