@use '@src/_variables.scss' as v;

.user-card {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin: 20px auto;

  .info {
    display: flex;
    width: 147px;
    align-items: center;
    gap: 20px;

    .text {
      text-align: left;
    }
  }

  .icon-background {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: v.$primary-color;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    color: #ffffff;
  }

  .text {
    color: var(--Blue, #3d2f90);
    font-feature-settings: 'calt' off;
    font-family: v.$font-family-roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  .form-container {
    display: flex;
    align-items: flex-start;
    width: 700px;
    padding: 20px;
    gap: 120px;
    border: 1px solid #eaecef;
    background: var(--White, #fff);

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      flex: 1 0 0;

      .row {
        display: flex;
        align-items: center;
        gap: 20px;
        align-self: stretch;
        flex-wrap: nowrap;
        margin: 0;

        .form-field {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;
          align-self: stretch;
          width: 100%;
          margin: 0;

          .title {
            color: var(--Navy, #271d5a);
            font-feature-settings: 'calt' off;
            font-family: v.$font-family-roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            margin: 0;
          }
        }

        .action {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 8px;
          align-self: stretch;
          width: 100%;
          font-family: 'Inter';
          font-weight: 700;
        }
      }
    }
  }
}
