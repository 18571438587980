.App-submodule-header {
  display: flex;
  justify-content: space-between;

  .scheduler-button {
    height: 38px;
    padding: 0 20px;
    margin-top: 22px;
    text-decoration: none;
    span {
      text-transform: uppercase;
    }
  }
}

.no-underline {
  text-decoration: none;
}

.schedule-event-icon {
  margin-right: 10px;
}
