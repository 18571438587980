.date-picker {
  &__input-container {
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 8px;
    padding-right: 32px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    cursor: pointer;

    &:focus {
      border-color: #4f46e5;
      box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &.has-suffix {
      padding-right: 32px;
    }
  }

  &__icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
    pointer-events: none;
  }
}

.date-picker__icon {
  color: #cad1d6 !important;
}
