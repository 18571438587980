.App-body {
  overflow: hidden;
}
.main-table {
  margin: 0 auto;
  width: 899px;
  border-collapse: separate;
  border-spacing: 0 20px;
  border: none;
  background-color: unset;
  &.review {
    width: 700px;
  }
}

.icon-cell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 148px;
}

.icon-circle {
  width: 40px;
  height: 40px;
  background-color: #3d2f90;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: white;
  width: 20px;
  height: 20px;

  &.building-table {
    width: 19px;
    height: 19px;
  }
}

.content-cell {
  vertical-align: top;
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  border: 1px solid #eaecef;
  &.review {
    padding: 0;
  }
}

.section-title {
  text-align: left !important;
  margin-left: 20px;
  width: 87px;
}
.form-field {
  margin: 0;
}

.schedule-inputs {
  .form-field {
    input {
      height: 38px;
      font-family: 'Ubuntu', 'Helvetica', sans-serif;
      font-size: 13px;
    }
  }
}

.schedule-info {
  margin-top: 14px;
  font-style: italic;
  color: #626262;
}

.schedule-inputs,
.program-select {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.program-select {
  .dropdown__control {
    width: 330px;
    height: 38px;
  }

  .dropdown__menu {
    max-height: 200px;
    > div {
      height: auto !important;
    }
  }
}

.input-group {
  flex: 1;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.date-input {
  position: relative;
}

.building-table {
  border: none;
  thead {
    tr:first-child {
      border-top: none;
    }
    tr {
      th {
        padding: 20px;
        .header {
          &.input {
            input {
              height: 15px;
              padding: 10px;
              width: 100%;
              padding: 8px;
              border: 1px solid #d1d5db;
              border-radius: 4px;
              box-sizing: border-box;
            }
            svg {
              padding-right: 12px;
              color: rgb(72, 84, 101);
              opacity: 0.5;
              width: 15px;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    tr:first-child {
      td {
        padding-top: 20px;
      }
    }
    tr:last-child {
      td {
        padding-bottom: 20px;
      }
    }
  }
}

.building-table + .pagination {
  border-top: 1px solid #eaecef;
  margin: 0;
  padding: 10px 20px 20px 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
