.step-font {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.stepper-wrapper {
  background-color: #ffffff;
}

.stepper {
  margin: 0 auto;
  padding: 15px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-title {
  font-weight: bold;
}

.step-indicators {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-indicator-label {
  display: flex;
  align-items: center;
  flex: 1;
}

.step-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #a7b2bb;
  color: #a7b2bb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.step-indicator.active {
  background-color: #e3e0f5;
  border: 1px solid #3d2f90;
  color: #3d2f90;
}

.step-indicator.completed {
  background-color: #60a782;
  border: 1px solid #60a782;
  color: white;
}

.step-label {
  font-size: 14px;
  color: #666;
}

.step-label.active {
  font-weight: bold;
  color: #3d2f90;
}

.step-separator {
  width: 40px;
  height: 1px;
  background-color: #a7b2bb;
  margin: 0 10px;
  flex-grow: 1;
}

.step-separator.active {
  background-color: #e3e0f5;
}

.stepper-buttons {
  display: flex;
  justify-content: space-between;
}

.back-button,
.next-button {
  gap: unset !important;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button {
  background: white;
  border: 1px solid #3d2f90;
  color: #3d2f90;
}

.cancel-button {
  background: #ffffff;
  color: #1bd3c5;
  border: none;
}

.back-button:hover:not(:disabled) {
  background: #f0f8ff;
}

.next-button {
  background: #3d2f90;
  color: white;
}

.completed-button {
  background: white;
  color: #3d2f90;
  border: 1px solid #3d2f90;
  border-radius: 2px;
  font-weight: 700;
  font-size: 14px;
}

.back-button:disabled,
.next-button:disabled {
  cursor: not-allowed;
  background: #ffffff;
  color: #e3e0f5;
  border: 1px solid #e3e0f5;
}
