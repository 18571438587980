$background-color: #fafafa;
$primary-color: #3d2f90;
$secondary-color: #19d3c5;
$primary-font-color: #261d5a;
$secondary-font-color: #19d3c5;
$font-stack: 'Ubuntu', 'Helvetica', sans-serif;
$primary-gradient-step1-color: #261d5a;
$primary-gradient-step2-color: #1c182f;
$secondary-gradient-step1-color: #0e776e;
$secondary-gradient-step2-color: #052e2b;
$navy: #271d5a;
$light-grey: #eaecef;
$mid-grey: #a7b2bb;
$dark-grey: #212529;
$white: #fff;
$font-family-roboto: 'Roboto', sans-serif;
