@use '../../variables' as v;

.modal {
  position: fixed;
  width: 580px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: v.$primary-font-color;
  z-index: 1040;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  .header {
    padding: 16px 16px 16px 16px;
    border-bottom: 1px solid #d2d4d6;
  }
  .content {
    overflow: auto;
    max-height: 475px;
  }
  .footer {
    padding: 16px 16px 16px 16px;
    border-top: 1px solid #d2d4d6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
  }
}
.create {
  font-size: 13px;
  cursor: pointer;
  font-weight: bold;
}
.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}
