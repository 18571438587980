.review-title {
  text-align: left !important;
}

.review-subtitle {
  font-size: 14 !important;
}

.title-container {
  padding: 10px;
}

.review-row {
  display: flex;
  width: 660px;
  &.justify-between {
    justify-content: space-between;
  }
  &.subwrapper {
    width: 210px;
  }
}

.review-wrapper {
  padding: 10px;
  width: 330px;
  border: 1px solid #eaecef;
  &.subtitle {
    background-color: #fafafa;
    text-align: left !important;
  }

  &.info {
    background-color: #ffffff;
    font-weight: 400;
    color: #697b8b;
    text-align: right;
  }
}

.location-title-wrapper {
  padding: 20px;
  border-bottom: 1px solid #eaecef;
}
