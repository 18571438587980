@use '@src/variables' as v;

.tile {
  background-color: #ffffff;
  color: v.$primary-font-color;
  box-shadow: 0 2px 4px 0 #a7b2bb;
  margin: 8px;
  border-radius: 4px;
  cursor: pointer;
  .header {
    margin: 17px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .body {
    margin: 17px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid v.$secondary-color;
    overflow: auto;
    .info {
      width: 225px;
      padding: 8px;
      .name {
        width: 50%;
        color: #212529;
        font-size: 14px;
      }
      .value {
        width: 50%;
        font-size: 18px;
      }
    }
  }
}
