.wrapper-navigation {
  display: flex;
}

.navigation-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #eaecef;
  border-bottom: 1px solid #eaecef;
  background: #fff;
  height: 46px;
}

.tabs-container {
  display: flex;
  gap: 40px;
}

.tab {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #271d5a;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s, border-color 0.2s;

  &.active {
    font-weight: 700;
    border-bottom: 2px solid #1bd3c5;
  }
}

.open-adr-dropdown {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 15px 20px;

  svg {
    color: #271d5a;
  }

  &.active {
    border-bottom: 2px solid #1bd3c5;
    svg path {
      stroke-width: 20;
      stroke: #271d5a;
    }

    .dropdown__single-value {
      font-weight: 700;
      font-size: 16px;
      text-decoration: none;
      color: #271d5a;
    }
  }
}

.partner-selector {
  font-size: 12px;
  width: 200px;
  text-align: left;

  .dropdown__control {
    box-shadow: none !important;
  }
}

.open-adr-selector {
  .dropdown__placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #271d5a;
    transition: color 0.2s, border-color 0.2s;
  }

  .dropdown-container {
    width: inherit !important;
  }

  .dropdown__control {
    box-shadow: none !important;
  }

  .dropdown__indicator {
    padding: inherit !important;
  }

  .dropdown__value-container {
    padding: 0 10px 0 0 !important;
  }

  .dropdown__menu {
    width: 150px;
    left: -18px;
    text-align: left;
  }
}

.tab-separator {
  border: 1px solid #eaecef;
  margin: 10px 0;
}
