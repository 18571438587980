@use '@src/_variables.scss' as v;

.wrapper-header {
  display: flex;
  border-top: 1px solid v.$light-grey;
  border-bottom: 1px solid v.$light-grey;
}

.header-tabs {
  width: 100%;
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px v.$light-grey;
  border-bottom: 1px solid v.$light-grey;
  background: v.$white;
}

.back-button {
  display: flex;
  float: left;
  align-items: center;
  gap: 20px;
  border: none;

  .arrow {
    color: v.$navy;
  }

  .text {
    color: v.$navy;
    font-family: v.$font-family-roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.icon {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}
