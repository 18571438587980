@use '../../variables' as v;

.heading-wrapper {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-button {
  color: v.$primary-color;
}
