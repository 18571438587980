@use '@src/_variables.scss' as v;

.building-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 2px;
  border: 1px solid v.$light-grey;
  background: v.$white;

  box-shadow: 0px 2px 4px 0px v.$light-grey;

  .site-header {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: stretch;
    margin: 0;

    border-radius: 2px;
    border: 1px solid v.$light-grey;
    background: v.$background-color;

    svg {
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      color: v.$primary-color;
      margin: '0';
      cursor: 'pointer';
    }

    .dropdown-selector {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;

      .dropdown-container {
        width: 100%;
      }
    }
  }

  .building-header {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 2px;
    border-right: 1px solid v.$light-grey;
    border-left: 1px solid v.$light-grey;
    background: v.$white;

    .selection-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;

      .options {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        line-height: 16px;

        .selected {
          color: v.$dark-grey;
          font-family: v.$font-family-roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }

        .select-all {
          color: v.$primary-color;
          font-family: v.$font-family-roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    .search {
      display: flex;
      min-width: 250px;
      padding: 10px;
      align-items: center;
      gap: 12px;

      border-radius: 2px;
      border: 1px solid v.$light-grey;
      background: v.$white;

      /* card 2 */
      box-shadow: 0px 2px 4px 0px v.$light-grey;

      svg {
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        padding: 0 !important;
        color: v.$mid-grey;
      }

      input {
        padding: 0;
        box-shadow: none;
        font-family: v.$font-family-roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }

  .building-table-container {
    max-height: 275px;
    overflow: auto;
    width: 100%;

    tr {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      border-top: 1px solid v.$light-grey;

      td {
        padding: 0;

        .row-checkbox {
          width: 13px;
          height: 13px;
          flex-shrink: 0;

          border-radius: 2px;
          border: 1px solid v.$primary-color;
          background: v.$primary-color;
          box-shadow: none;
          accent-color: v.$primary-color;
        }

        .building-row {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 10px;

          .name {
            color: v.$primary-color;
            font-family: v.$font-family-roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }

          .address {
            color: v.$mid-grey;
            font-family: v.$font-family-roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
    }
  }
}

body {
  background-color: #fafafa;
}

body > .dropdown__menu-portal > .dropdown__menu {
  position: relative !important;
}
