.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;

  &.relative {
    position: absolute;
    height: 100%;
  }

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(244, 244, 244, 0.8) !important;
  }
}

.loader-content {
  display: flex;
  align-items: center;
}

.spinner {
  margin-right: 8px;
}

.loader-text {
  animation: none !important;
}
