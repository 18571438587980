@use '@src/variables' as v;

.edit-box {
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
  .header {
    height: 37px;
    background-color: v.$primary-color;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    align-items: center;
    display: flex;
    padding-left: 16px;
  }
  .content {
    min-height: 500px;
    text-align: left;
    margin: 8px;
  }
  .footer {
    border-top: 1px solid #d2d4d6;
    color: v.$secondary-font-color;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px 12px 12px;
  }

  .button-disabled {
    background: linear-gradient(0deg, #323232 0%, #666666 25.53%, #979797 100%);
    color: #ffffff;
  }
}
