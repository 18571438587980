.pagination {
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
}

.header {
    margin-top: 8px;
    &.input {
    border: 1px solid #DFE3E9 !important;
    border-radius: 2px;
    padding: 8px;
    display: flex;
    }
}

.column-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #A7B2BB;
    border-radius: 2px;
    background-color: #EAECEF;
    padding: 4px 12px;
    margin-bottom: 10px;
    &.active {
        background-color: #FFFFFF;
    }
}

.column-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 16px 0px 16px;
    &.active {
        background-color: #EAECEF;
    }
    .helper {
        text-align: center;
        margin: 16px;
        padding: 8px;
        border: 1px dashed #A7B2BB;
        border-radius: 2px;
    }
}

#clear-filter {
    width: 100px;
}