.tab-switch-selector {
  display: flex;
  border-radius: 0.375rem;
  margin-top: 20px;
  margin-bottom: 20px;
  .tab-switch {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
    background-color: #ffffff;
    border: 1px solid #cad1d6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:first-child {
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
    }

    &:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
    }

    &:not(:last-child) {
      border-right: none;
      color: #3d2f90;
    }

    &:hover {
      background-color: #f3f4f6;
    }

    &:focus {
      outline: none;
      z-index: 1;
    }

    &.active {
      color: #ffffff;
      background-color: #3d2f90;
    }
  }
}
