@use '../../variables' as v;

.wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    overflow: hidden;
    border: solid 1px #CAD1D6;
    width: 300px;
    text-align: center;
    font-family: v.$font-stack;
    .item {
        font-size: 12px;
        text-transform: uppercase;
        color: v.$primary-font-color;
        padding: 8px 4px 8px 4px;
        background-color: #FFFFFF;
        border-left: solid 1px #CAD1D6;
        margin-left: -1px;
        flex-grow: 1;
        cursor: pointer;
        &.selected {
            background-color: v.$primary-color;
            color: #FFFFFF;
        }
    }
}