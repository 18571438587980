.time-picker {
  position: relative;
  width: 16rem; // 64px * 4

  .time-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .picker-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    z-index: 1;

    .picker-content {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      height: 200px;

      .scrollable-list {
        height: 100%;
        overflow-y: auto;
        flex: 1;

        .option {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background-color: #f0f0f0;
          }

          &.selected {
            background-color: #e0e0e0;
          }
        }
      }
    }
  }
}
